export const BRACKETS = ['{{', '}}']
export const TRANSLATION_BRACKETS = ['<[', ']>']

export function escapeRegex(string: string): string {
  return String(string).replace(/[\\^$*+?.()|[\]{}]/g, '\\$&')
}

export function format(string: string, kwargs: Record<string, any>, brackets = BRACKETS): string {
  let str = String(string)
  const start = escapeRegex(brackets[0])
  const end = escapeRegex(brackets[1])

  Object.keys(kwargs).forEach(key => {
    str = str.replace(new RegExp(`${start}*${key}*${end}`, 'img'), kwargs[key])
  })

  return str
}

export function formatTranslation(string: string, kwargs: Record<string, any>): any {
  return format(string, kwargs, TRANSLATION_BRACKETS)
}
