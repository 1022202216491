import { App } from 'vue'

import Controller from '@/components/controls/ControlDescriptor.vue'
import InputLabel from './Label.vue'
import InputTextArea from './TextArea.vue'
import SimpleInput from './SimpleInput.vue'
import Multiselect from './Multiselect.vue'
import UiDatepicker from './UiDatepicker.vue'

const register = (app: App<Element>): void => {
  const prefix = 'DControl'
  app
    .component(`${prefix}Input`, Controller)
    .component(`${prefix}Label`, InputLabel)
    .component(`${prefix}Textarea`, InputTextArea)
    .component(`${prefix}SimpleInput`, SimpleInput)
    .component(`${prefix}Multiselect`, Multiselect)
    .component(`${prefix}Datepicker`, UiDatepicker)
}

export default {
  register,
}
