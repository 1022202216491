import { route } from '@/router/helpers'

import {
  createRoutes as PagesRoutes,
  AUTH_PAGE_NAME,
  DOMAINS_PAGE_NAME,
} from '@/components/pages/routes'

import {
  EDU_ACCESS_TOKEN_STORAGE_NAME,
  EDU_REFRESH_TOKEN_STORAGE_NAME,
} from '@/const'

import { storage } from '@/utils/session'

import InitialView from '@/components/InitialView.vue'

const INITIAL_VIEW_URL = '/'

export const INITIAL_VIEW_NAME = 'init'

const getRedirect = () => {
  const is_auth = !!storage.getItem(EDU_ACCESS_TOKEN_STORAGE_NAME)
  return is_auth ? DOMAINS_PAGE_NAME : AUTH_PAGE_NAME

}

export default function createExtentionRoutes(options: Record<string, any>): any[] {
  return [
    route(INITIAL_VIEW_URL, InitialView, INITIAL_VIEW_NAME, {
      children: [
        ...PagesRoutes(options),
      ],
      redirect: {
        name: getRedirect()
      },
    }),
  ]
}
