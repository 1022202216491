import { App } from 'vue'

import BaseHeader from './header.vue'
import BaseFooter from './footer.vue'


const register = (app: App<Element>): void => {
  app
    .component('BaseHeader', BaseHeader)
    .component('BaseFooter', BaseFooter)
}

export default {
  register,
}