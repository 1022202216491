import { App } from 'vue'
import { formatTranslation } from './utils'
import { URLsResolver } from './urls'
import { makeClient, Client } from './client'
import { makeRouterLocalePatchGuard } from './router'
import { storage } from '@/utils/session'
import { EDU_LOCALE_DIRECTION } from '@/const'

function format(string: string, kwargs: any) {
  return formatTranslation(this.$t(string), kwargs)
}

export default {
  install(app: App, options = {}): void {
    const client = makeClient(options)

    app.use(client.i18n)

    app.config.globalProperties.$i18nClient = client
    app.config.globalProperties.$t = e => e
    app.config.globalProperties.$tf = format
    app.config.globalProperties.$turl = client.urlsResolver

    app.config.globalProperties.$router.beforeResolve(
      makeRouterLocalePatchGuard(client, app)
    )

    client.hook('locale:changed', locale => {
      client.i18n.locale = locale
      app.config.globalProperties.$i18n.locale = locale
    })
  }
}

// declare module 'vue/types/vue' {
//   interface Vue {
//     $i18nClient: Client,
//     $tf: ReturnType<typeof format>
//     $turl: URLsResolver
//   }
// }
