import { format } from '@/modules/format'
import { Client } from './client'
import { nextTick } from 'vue'
import { getCacheBackend } from '@/modules/cache'
// import fetch from 'isomorphic-unfetch'

export const TRANSLATION_BRACKETS = ['<[', ']>']
// BE CAREFUL HERE!
// No not use cache globally all the time.
// Cache is global because there is sense in doing it like that.
export const MESSAGES_CACHE = new (getCacheBackend())()
export const MESSAGES_TTL = 60 // seconds
export const MESSAGES_URL = '/locale/'

export function formatTranslation(string: string, kwargs: Record<string, any>): string {
  return format(string, kwargs, TRANSLATION_BRACKETS)
}

// TODO: Set this as method to a client.
export async function loadI18nMessages(client: Client, locale: string): Promise<any> {
  const url = `/locales/${locale}.yml`
  // const messagesGetter= (await )
  const messages = await MESSAGES_CACHE.getOrSet(
    locale, async () => await fetch(url).then(r => {
      return r.arrayBuffer();
    }).then(buffer => {
      const decoder = new TextDecoder();
      const text = decoder.decode(buffer);
      return JSON.parse(text)
    }), { ttl: MESSAGES_TTL }
  )
  client.i18n['global'].setLocaleMessage(locale, messages )
  return await nextTick()
}
