import {
  createSender,
  createReceiver,
  createSimpleSender,
} from '@/resource/resource'

import {
  prefixAPI,
  API_EXTERNAL_URL,
} from '@/resource/api'

const MODEL = 'auth'

const AUTHENTICATE_LINK = prefixAPI('/jwt/create/', MODEL, API_EXTERNAL_URL)
const REFRESH_TOKEN_LINK = prefixAPI('/jwt/refresh/', MODEL, API_EXTERNAL_URL)
const USER_RETRIEVE_LINK = prefixAPI('/users/me/', MODEL, API_EXTERNAL_URL)
const USER_AUTH_AS_LINK = prefixAPI('/as', MODEL, API_EXTERNAL_URL)

export const authenticateCreate = createSender(AUTHENTICATE_LINK)
export const refreshToken = createSender(REFRESH_TOKEN_LINK)

export const getUser = createReceiver(USER_RETRIEVE_LINK)
export const userAuthAs = createSender(USER_AUTH_AS_LINK)
