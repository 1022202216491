import {
  useAuth,
} from '@/stores/auth'

/**
 * logout
 * @returns resolved Promise
 */
export default async function useLogout(): Promise<boolean> {
  return new Promise(resolve => {
    const store = useAuth()
    store.deleteTokens()
    resolve(true)
  })
}