import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "block-container" }
const _hoisted_2 = { class: "domain-container" }
const _hoisted_3 = { class: "g-row g-row--appearance_spaced g-row--justify_between g-row--align_center" }
const _hoisted_4 = { class: "g-cell g-cols g-cols--narrow-xs" }
const _hoisted_5 = { class: "ds-caption ds-caption--bold ds-caption--size_hd" }
const _hoisted_6 = { class: "g-cell g-cols g-cols--narrow-xs" }
const _hoisted_7 = { class: "button__icon" }
const _hoisted_8 = { class: "button__text" }
const _hoisted_9 = { class: "relative" }
const _hoisted_10 = { class: "ui-table" }
const _hoisted_11 = { class: "ui-table__head" }
const _hoisted_12 = { class: "table-header" }
const _hoisted_13 = {
  key: 0,
  class: "ui-table__body"
}
const _hoisted_14 = { key: 1 }
const _hoisted_15 = { colspan: "2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_i_plus = _resolveComponent("i-plus")!
  const _component_ui_loader = _resolveComponent("ui-loader")!
  const _component_ui_pagination = _resolveComponent("ui-pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h1", _hoisted_5, _toDisplayString(_ctx.$t('Домены')), 1)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("button", {
            class: "button button--appereance_border",
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => ($setup.addDomainOpenModal()), ["prevent"]))
          }, [
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_i_plus)
            ]),
            _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t('Добавить домен')), 1)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        ($setup.isLoad)
          ? (_openBlock(), _createBlock(_component_ui_loader, { key: 0 }))
          : _createCommentVNode("", true),
        _createElementVNode("table", _hoisted_10, [
          _createElementVNode("thead", _hoisted_11, [
            _createElementVNode("tr", _hoisted_12, [
              _createElementVNode("td", null, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('Домен')), 1)
              ]),
              _createElementVNode("td", null, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('Статус')), 1)
              ])
            ])
          ]),
          ($setup.domainsList.length)
            ? (_openBlock(), _createElementBlock("tbody", _hoisted_13, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.domainsList, (item) => {
                  return (_openBlock(), _createElementBlock("tr", null, [
                    _createElementVNode("td", null, _toDisplayString(item.domain), 1),
                    _createElementVNode("td", null, [
                      _createElementVNode("span", {
                        class: _normalizeClass(item.status == '0-act' ? 'color_green' : 'color_red')
                      }, _toDisplayString(item.status == '0-act' ? _ctx.$t('Активен') : _ctx.$t('Скомпрометирован')), 3)
                    ])
                  ]))
                }), 256))
              ]))
            : (_openBlock(), _createElementBlock("tr", _hoisted_14, [
                _createElementVNode("td", _hoisted_15, _toDisplayString(_ctx.$t('No data to display')), 1)
              ]))
        ]),
        ($setup.pagination.total > 10)
          ? (_openBlock(), _createBlock(_component_ui_pagination, {
              key: 1,
              current: $setup.pagination.page,
              total: $setup.pagination.total,
              perPage: $setup.pagination.page_size,
              onPageChanged: _cache[1] || (_cache[1] = ($event: any) => (_ctx.getBuyers($event)))
            }, null, 8, ["current", "total", "perPage"]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}