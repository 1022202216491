/* eslint-disable */
import {
  prefixLanguage,
} from '@utils/urls'

// префикс апи
export const API_BASE_URL = '/'
// export const API_BASE_URL = '/api/v1/'

export const API_EXTERNAL_URL =  '/'
// export const API_EXTERNAL_URL = process.env.NODE_ENV !== 'production' ? 'http://propeller.webcase-dev.com/' : '/'

export const prefixAPI = (url, model = '', base = API_BASE_URL) => base + model + url
