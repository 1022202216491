import { App } from 'vue'

import { Form, Field, ErrorMessage, defineRule } from './index'
import {
  required,
  email,
  confirmed,
  min,
  url,
} from './rules'

export default {
  install(app: App<Element>): void {
    app.component('VeeForm', Form)
    app.component('VeeField', Field)
    app.component('VeeError', ErrorMessage)
    defineRule('required', required.bind(app))
    defineRule('email', email.bind(app))
    defineRule('confirmed', confirmed.bind(app))
    defineRule('min', min.bind(app))
    defineRule('url', url.bind(app))
  }
}
