import { App } from 'vue'

import AuthForm from './auth-form.vue'
import AddDomainForm from './add-domain.vue'



const register = (app: App<Element>): void => {
  app
  .component('AuthForm', AuthForm)
  .component('AddDomainForm', AddDomainForm)
}

export default {
  register,
}