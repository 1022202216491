import { defineAsyncComponent, DefineComponent } from 'vue'
import {
  Constructor,
  ComponentProps,
  useModal,
  useModalSlot,
  VueFinalModal,
} from 'vue-final-modal'

const ModalWrapper = defineAsyncComponent(
  () => import('@/components/modal/modal-container.vue')
)

type MayBeAny = any

type FinishFn = (arg: MayBeAny) => void
type FinishPromise = (arg: MayBeAny) => Promise<MayBeAny> | Promise<void>
type Finish = FinishFn | FinishPromise

interface ModalControls {
  open: () => Promise<string>
  close: () => Promise<string>
  patchOptions: (options: MayBeAny) => void
  destroy: () => void
}

export function useModalOpener(
  componentProps: { component: DefineComponent; [key: string]: MayBeAny },
  finish: Finish = (arg: unknown) => undefined
): ModalControls {
  const onFinish = (arg: unknown) =>
    new Promise((resolve, reject) => {
      if (arg !== undefined) {
        resolve(arg)
        return
      }
      reject()
    })
      .then((result) => {
        finish(result)
        close()
      })
      .catch((err) => {
        console.warn('use-modal-on-finish', err)
      })
  const { options, open, close, patchOptions, destroy } = useModal({
    component: ModalWrapper as Constructor<any>,
    attrs: {
      onClose() {
        close()
      },
      onFinish,
      ...componentProps,
    },
  })

  return { open, close, patchOptions, destroy }
}
