<template>
<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M7.29964 6.58643C7.10314 6.76952 6.94554 6.99033 6.83623 7.23566C6.72691 7.48099 6.66814 7.74582 6.6634 8.01437C6.65866 8.28291 6.70806 8.54965 6.80865 8.79869C6.90924 9.04772 7.05896 9.27394 7.24887 9.46386C7.43879 9.65378 7.66501 9.8035 7.91405 9.90409C8.16308 10.0047 8.42983 10.0541 8.69837 10.0493C8.96691 10.0446 9.23174 9.98582 9.47708 9.87651C9.72241 9.7672 9.94321 9.60959 10.1263 9.41309" stroke="#7285AB" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M7.86621 3.38683C8.14708 3.35184 8.42983 3.33402 8.71288 3.3335C13.3795 3.3335 15.3795 8.00016 15.3795 8.00016C15.0815 8.63824 14.7077 9.23812 14.2662 9.78683" stroke="#7285AB" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M5.11948 4.40674C3.79364 5.30982 2.73272 6.55024 2.04614 8.00007C2.04614 8.00007 4.04614 12.6667 8.71281 12.6667C9.99008 12.6702 11.24 12.2968 12.3061 11.5934" stroke="#7285AB" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M2.04614 1.3335L15.3795 14.6668" stroke="#7285AB" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


</template>
