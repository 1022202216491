import { createPinia } from 'pinia'
import { App as AppType } from 'vue'
import createRouter from '@/router'
import App from './App.vue'
import Components from '@/components'
import Modules from '@/modules'
import DatePicker from 'vue-datepicker-next'
import 'vue-datepicker-next/index.css'
type createApplication = { createApp: any, createRouter: any, createWebHistory: any }

export function createApplication({ createApp, createRouter: createInstance, createWebHistory }:createApplication): AppType {
  const app = createApp(App)
  const pinia = createPinia()
  const router = createRouter({ app, createInstance, createWebHistory })
  app.use(router)
  app.use(pinia)
  app.use(Modules)
  app.component('datepicker', DatePicker)
  
  Components.installComponents(app)
  
  app.mount('#app')
  return app
}
