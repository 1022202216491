import { mergeProps as _mergeProps, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-control-input d-control-input--appearance_bordered" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("textarea", _mergeProps({ class: "d-control-input__element" }, _ctx.$attrs, {
      onInput: _cache[0] || (_cache[0] = e => _ctx.$emit("update:modelValue", e.target.value))
    }), null, 16)
  ]))
}