import { type ClientMakerOptions } from './client'

export function getNumberFormats({ locale }: ClientMakerOptions): Record<string, any> {
  return {
    [locale] : {
      decimal: {
        style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0,
      },
    }
  }
}

export function getDatetimeFormats(options: ClientMakerOptions): Record<string, any> {
  return {}
}

export function getFormats(options: ClientMakerOptions): Record<string, any> {
  return {
    numberFormats: getNumberFormats(options),
    datetimeFormats: getDatetimeFormats(options),
  }
}
