import { acceptHMRUpdate, defineStore as defineStoreBase } from 'pinia'

export const defineStore = ((id, setup, options) => {
  const result = defineStoreBase(id, setup, options)

  if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(result, import.meta.hot))
  }

  return result
}) as typeof defineStoreBase
