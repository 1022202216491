import { App } from 'vue'

import i18n from './i18n/plugin'
import Validation from './validation/plugin'
import { storage } from '@/utils/session'
import { EDU_CURRENT_LOCALE } from '@/const'

const prettifyLocale = (locale) => {
  try {
    return locale.split('-')[0]
  } catch (e) {
    return storage.getItem(EDU_CURRENT_LOCALE)
  }
}
const chrome = {}
export default {
  install(app: App<Element>): void {
    app
      .use(Validation)
      .use(i18n,  { locale: 'en' })
  }
}
