import {
  refreshToken,
} from '@/services/auth.service'
import useAuthToken from '@/composables/useAuthToken'
import {
  useAuth,
} from '@/stores/auth'
import type {
  Router,
} from 'vue-router'
import {
  AUTH_PAGE_NAME,
} from '@/components/pages/routes'

import useLogout from '@/composables/useLogout'

const FORBIDDEN = 403
const SERVER_ERROR = 500
const OTHER_ERROR = 422

export default async function executeWithRefresh(resource: Record<string, any>, token?: string|null, params?: object, payload?: object|null, router?: Router): Promise<any> {
  const resourceWithMiddleware = (await useAuthToken(resource, token))
  try {
    const result = (await resourceWithMiddleware.execute(params, payload || null))
    return result
  } catch (e: any) {
    if (e.status === FORBIDDEN) {
      const store = useAuth()
      try {
        const resourceWithMiddleware = refreshToken
        const { access } = (await resourceWithMiddleware.execute({}, {refresh: store.refreshToken}))
        return executeWithRefresh(resource, access, params, payload)
      } catch (error: any) {
        if (error.status) {
          await useLogout()
          if (router) {
            router.push({
              name: AUTH_PAGE_NAME,
            })
          }
          return Promise.reject(error);
        }
      }
    }
    return Promise.reject(e)

  }

}