import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "modal-backdrop" }
const _hoisted_2 = { class: "overflow" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_i_close = _resolveComponent("i-close")!
  const _component_VueFinalModal = _resolveComponent("VueFinalModal")!

  return (_openBlock(), _createBlock(_component_VueFinalModal, _mergeProps({
    "overlay-transition": 'vfm-fade',
    "content-transition": 'vfm-fade'
  }, $setup.props), {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", {
          class: "modal-background",
          onClick: $setup.close
        }),
        _createElementVNode("div", {
          class: _normalizeClass(["modal", _ctx.$attrs.classes]),
          role: "dialog"
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("button", {
              class: _normalizeClass(["modal-close", { "is-simple": !_ctx.$attrs.isDifficult }]),
              type: "button",
              onClick: $setup.close
            }, [
              _createVNode(_component_i_close)
            ], 2),
            _renderSlot(_ctx.$slots, "default"),
            (_openBlock(), _createBlock(_resolveDynamicComponent($setup.isComponent), _mergeProps(_ctx.$attrs, {
              close: $setup.close,
              onFinish: $props.onFinish
            }), null, 16, ["onFinish"]))
          ])
        ], 2)
      ])
    ]),
    _: 3
  }, 16))
}