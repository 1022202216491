import { App } from 'vue'
import VueMultiselect from "vue-multiselect"

import Base from './base'
import UiElements from './ui'
import IconsElements from './i'
import Controls from './controls'
import Forms from './forms'
import Modal from './modal'

const installComponents = (app: App<Element>): void => {
  Base.register(app)
  UiElements.register(app)
  IconsElements.register(app)
  Controls.register(app)
  Forms.register(app)
  app.use(Modal)
  app.component('VueMultiselect', VueMultiselect)
}

export default {
  installComponents,
}
