import { App } from 'vue'

import UiLoader from './loader.vue'
import UiAccordion from './ui-accordion.vue'
import UiClickOutside from './ui-click-outside.vue'
import UiPagination from './pagination.vue'
import SidebarTrigger from './sidebar-trigger.vue'


const register = (app: App<Element>): void => {
  app
  .component('UiLoader', UiLoader)
  .component('UiAccordion', UiAccordion)
  .component('UiClickOutside', UiClickOutside)
  .component('UiPagination', UiPagination)
  .component('SidebarTrigger', SidebarTrigger)
}

export default {
  register,
}