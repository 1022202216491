import { App } from 'vue'
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';

import { Client } from './client'
// import { loadI18nMessages } from './utils'
import { LOCALES as SUPPORT_LOCALES } from './const'
import { storage } from '@/utils/session'
import { EDU_CURRENT_LOCALE } from '@/const'


export function makeRouterLocalePatchGuard(client: Client, app: App<Element>): (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => Promise<void> {
  return async function (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> {
    const selectedLocale = storage.getItem(EDU_CURRENT_LOCALE);

    let browserLocale: string = window.navigator.language.replace('-', '_');
    
    let hasBrowser: boolean = SUPPORT_LOCALES.includes(browserLocale);

    if (!browserLocale.includes('_')) {
      const foundLocale = SUPPORT_LOCALES.find(e => e.startsWith(browserLocale.split('_')[0]));
      browserLocale = foundLocale || browserLocale;
      hasBrowser = Boolean(foundLocale);
    }
    
    const locale = selectedLocale || (hasBrowser ? browserLocale : client.defaultLocale);

    // await loadI18nMessages(client, locale);

    await client.setLocale(locale);

    return next();
  };
}