import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, mergeProps as _mergeProps, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_i_close = _resolveComponent("i-close")!
  const _component_ui_loader = _resolveComponent("ui-loader")!
  const _directive_observe_visibility = _resolveDirective("observe-visibility")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["d-control-descriptor", { error: $props.errors[_ctx.name] || !$setup.isValid, success: $props.isSuccess }])
  }, [
    ($setup.model && $setup.model.length != 0 && !$props.disabled && $props.hideClearBtn != true)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "clear-multiselect",
          onClick: $setup.clearMultiselect
        }, [
          _createVNode(_component_i_close)
        ]))
      : _createCommentVNode("", true),
    _createVNode($setup["VueMultiselect"], _mergeProps({
      class: ["d-control-descriptor__element", { multiple: $props.multiple }]
    }, _ctx.$attrs, {
      options: $props.options,
      label: $props.labelBy,
      "track-by": $props.trackBy,
      placeholder: $props.placeholder,
      disabled: $props.disabled,
      "allow-empty": $props.allowEmpty,
      modelValue: $setup.model,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.model) = $event)),
      required: $props.rules,
      multiple: $props.multiple,
      "show-labels": false,
      "group-select": true,
      taggable: false
    }), _createSlots({ _: 2 }, [
      _renderList(_ctx.eSlots, (name, key) => {
        return {
          name: name,
          fn: _withCtx((binded) => [
            _renderSlot(_ctx.$slots, key, _normalizeProps(_guardReactiveProps(binded)))
          ])
        }
      }),
      ($props.pagination && Math.ceil($props.pagination.total/$props.pagination.page_size) > $props.pagination.page)
        ? {
            name: "afterList",
            fn: _withCtx(() => [
              _createElementVNode("div", null, [
                _withDirectives((_openBlock(), _createElementBlock("div", null, [
                  _createElementVNode("div", _hoisted_1, [
                    _createVNode(_component_ui_loader)
                  ])
                ])), [
                  [_directive_observe_visibility, $setup.visibilityChanged]
                ])
              ])
            ]),
            key: "0"
          }
        : undefined
    ]), 1040, ["class", "options", "label", "track-by", "placeholder", "disabled", "allow-empty", "modelValue", "required", "multiple"])
  ], 2))
}