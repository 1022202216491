<template>
<svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1204_20334)">
<path d="M1.73682 3.92383H10.7368" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.73682 3.92383V10.9238C9.73682 11.4238 9.23682 11.9238 8.73682 11.9238H3.73682C3.23682 11.9238 2.73682 11.4238 2.73682 10.9238V3.92383" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M4.23682 3.92383V2.92383C4.23682 2.42383 4.73682 1.92383 5.23682 1.92383H7.23682C7.73682 1.92383 8.23682 2.42383 8.23682 2.92383V3.92383" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_1204_20334">
<rect width="12" height="12" fill="white" transform="translate(0.236816 0.923828)"/>
</clipPath>
</defs>
</svg>


</template>
