export type CacheGetOptions = {
  stale: boolean;
};

export type CacheSetOptions = {
  ttl: number;
};

export type InMemoryCachePayload = {
  data: any; // Рекомендуется использовать более конкретный тип, если возможно
  expired: number;
};

export const TTL_MAX = Date.now() / 1000;

export class Cache {
  async get(key: string, options: Partial<CacheGetOptions>): Promise<any> {
    throw new Error("Method 'get' not implemented.");
  }
  async set(key: string, value: string|number, options: Partial<CacheSetOptions>): Promise<void> {
    throw new Error("Method 'set' not implemented.");
  }
  async invalid(key: string): Promise<void> {
    throw new Error("Method 'invalid' not implemented.");
  }
  async getOrSet(
    key: string,
    resolver: () => any, // Рекомендуется использовать более конкретный тип возвращаемого значения
    { stale = false, ttl = TTL_MAX }: Partial<CacheGetOptions & CacheSetOptions> = {}
  ): Promise<any> { // Укажите возвращаемый тип данных
    let value = await this.get(key, { stale });

    if (value !== null) return value;

    value = await resolver();

    await this.set(key, value, { ttl });

    return value;
  }
}

export class InMemoryCache extends Cache {
  storage: Record<string, InMemoryCachePayload>;

  constructor() {
    super();

    this.storage = {};
  }

  async hasExpired(payload: InMemoryCachePayload): Promise<boolean> {
    return Date.now() > payload.expired;
  }

  async get(key: string, { stale = false }: Partial<CacheGetOptions> = {}): Promise<any> { // Укажите возвращаемый тип данных
    const payload = this.storage[key];

    if (!payload) return null;
    if (await this.hasExpired(payload) && !stale) return null;

    return payload.data;
  }

  async set(
    key: string,
    value: string|number, 
    { ttl = TTL_MAX }: Partial<CacheSetOptions> = {}
  ): Promise<any> { // Укажите возвращаемый тип данных
    this.storage[key] = {
      data: value,
      expired: Date.now() + ttl * 1000,
    };

    return value;
  }

  async invalid(key: string): Promise<void> {
    const value = await this.get(key);

    if (value === null) return;

    await this.set(key, value, { ttl: 0 - TTL_MAX });
  }
}

export function getCacheBackend(): typeof InMemoryCache { // Укажите возвращаемый тип
  // TODO: Write the same cache but using localstorage or sessionstorage or websql or any other.
  return InMemoryCache;
}
