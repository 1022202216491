import { DefineComponent } from 'vue'

export function route(path: string, component: (() => Promise<typeof import("*.vue")>) | DefineComponent | never | any, name: string, options: object = {}): Record<string, any> {
  return {
    path, 
    component,
    name,
    ...options,
  }
}
