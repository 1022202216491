/* eslint-disable */
export function isNullOrUndefined(value: unknown): value is undefined | null {
  return value === null || value === undefined;
}

export function isEmptyArray(arr: unknown): boolean {
  return Array.isArray(arr) && arr.length === 0;
}

export function getSingleParam<TParam = unknown>(params: [TParam] | Record<string, TParam>, paramName: string) {
  return Array.isArray(params) ? params[0] : params[paramName]
}

export function isEmpty(value: unknown): boolean {
  if (value === null || value === undefined || value === '') {
    return true
  }

  if (Array.isArray(value) && value.length === 0) {
    return true
  }

  return false
}

function required(this: any, value: any) {
  if (isNullOrUndefined(value) || isEmptyArray(value) || value === false || !value) {
    return this.config.globalProperties.$t('This field is required')
  }
  return true
}

function email(this:any, value: any) {
  if (!value || !value.length) {
    return true
  }

  const EMAILREG = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  if (!EMAILREG.test(value)) {
    return this.config.globalProperties.$t('This field must be a valid email')
  }
  return true
}

function confirmed(this: any, value: any, [target]: string)  {
  if (value === target) {
    return true
  }
  return this.config.globalProperties.$t('Password did not match')
}

function min(this: any, value: unknown, params: [string | number] | { length: string | number }): boolean | string  {
  if (isEmpty(value)) {
    return true
  }

  const length = getSingleParam(params, 'length')
  if (Array.isArray(value)) {
    return value.every(val => min(val, { length }))
  }
  
  if (String(value).length >= Number(length)) {
    return String(value).length >= Number(length)
  } else {
    return `${this.config.globalProperties.$t('Min')}:${length}`
  }
}


function url(this:any, value: any) {
  if (!value || !value.length) {
    return true
  }

  const URLREG = /^https?:\/\/([\w\d\-]+\.)+\w{2,}(\/.+)?\/?$/
  if (!URLREG.test(value)) {
    return this.config.globalProperties.$t('This field must be a valid url')
  }

  return true
}



export {
  required,
  email,
  confirmed,
  min,
  url,
}
