/* eslint-disable */
const messageGetter = x => x.message

export function defaultValidatorErrorsParser(errors) {
  return errors.reduce((acc, x) => {
    const key = x.loc.pop()
    acc[key] = [x.msg]
    return acc
  }, {})
}

export async function useErrorsSetter(e, setFieldError) {
  const errors = {}
  await e.json().then(body => {
    body.errors.forEach(error => {
      if ('request' === error.domain && error.state) {
        Object.assign(errors, error.state)
      }
    })
  })
  const parsed = defaultValidatorErrorsParser(errors)

  Object.keys(parsed).forEach(key => {
    setFieldError(key, parsed[key][0])
  })
}

const noNetworkError = 'Failed to fetch'

export function useSubmit(send, control, returnAll) {
  function catchFormErrors(promise, controller) {
    return promise.catch(e => {
      const clientError = 400
      const serverError = 500
      if (!e.status || clientError > e.status || serverError <= e.status) {
        throw e
      }
  
      if (!updateValidator) {
        return e
      }
      
      return e.json().then(body => {
        if (returnAll) return body

        updateValidator(body.detail, controller)
  
        return e
      })
    })
  }
  
  function updateValidator(errors, controller) {
    controller.setFieldError('nonFieldErrors', '')
    if (typeof errors === 'string') {
      controller.setFieldError('nonFieldErrors', errors)
      return 
    }
    const parsed = defaultValidatorErrorsParser(errors)
    Object.keys(parsed).forEach(key => {
      controller.setFieldError(key, parsed[key][0])
    })
  }
  
  function submit(send, control) {
    return catchFormErrors(send, control)
  }

  const result = submit(send, control)
  return result

}
