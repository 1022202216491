export const EDU_DEVICE_UUID_COOKIE_NAME = 'EDU:DEVICE_UUID_COOKIE'

export const EDU_CURRENT_LOCALE = 'EDU:CURRENT_LOCALE'
export const EDU_ZENDESK_LOCALE = 'EDU:ZENDESK_LOCALE'
export const EDU_LOCALE_DIRECTION = 'EDU:LOCALE_DIRECTION'

export const EDU_CURRENT_ROUTE = 'EDU:CURRENT_ROUTE'
export const EDU_DJANGO_LANG = 'EDU:DJANGO_LANG'

export const EDU_ACCESS_TOKEN_STORAGE_NAME = 'EDU:ACCESS_TOKEN_STORAGE'
export const EDU_REFRESH_TOKEN_STORAGE_NAME = 'EDU:REFRESH_TOKEN_STORAGE'
export const EDU_DEVICE_UUID_STORAGE_NAME = 'EDU:DEVICE_UUID_STORAGE'


