import { App } from 'vue'
import Plus from './Plus.vue'
import Minus from './Minus.vue'
import IEye from './eye.vue'
import IEyeOff from './eye-off.vue'
import Download from './download.vue'
import Home from './home.vue'
import TableOrderBottom from './table-order-bottom.vue'
import TableOrderTop from './table-order-top.vue'
import ArrowDown from './arrow-down.vue'
import LoadMore from './load-more.vue'
import Edit from './edit.vue'
import Close from './close.vue'
import Save from './save.vue'
import History from './history.vue'
import arrowLeft from './arrow-left.vue'
import Copy from './copy.vue'
import Import from './import.vue'
import Property from './property.vue'
import Trash from './trash.vue'
import Dropdown from './dropdown.vue'

const register = (app: App<Element>, prefix='i-'): void => {
  app
  .component(`${prefix}plus`, Plus)
  .component(`${prefix}minus`, Minus)
  .component(`${prefix}eye`, IEye)
  .component(`${prefix}eye-off`, IEyeOff)
  .component(`${prefix}download`, Download)
  .component(`${prefix}home`, Home)
  .component(`${prefix}table-order-bottom`, TableOrderBottom)
  .component(`${prefix}table-order-top`, TableOrderTop)
  .component(`${prefix}arrow-down`, ArrowDown)
  .component(`${prefix}load-more`, LoadMore)
  .component(`${prefix}edit`, Edit)
  .component(`${prefix}close`, Close)
  .component(`${prefix}save`, Save)
  .component(`${prefix}history`, History)
  .component(`${prefix}arrow-left`, arrowLeft)
  .component(`${prefix}copy`, Copy)
  .component(`${prefix}import`, Import)
  .component(`${prefix}property`, Property)
  .component(`${prefix}trash`, Trash)
  .component(`${prefix}dropdown`, Dropdown)

}

export default {
  register,
}