<script>
import { $vfm } from 'vue-final-modal'
import ModalContainer from '@/components/modal/modal-container.vue'

export default {
  name: 'ModalTrigger',
  props: {
    component: {
      type: String,
      default: () => null,
    },
    name: {
      type: String,
    },
    items: {
      type: Object,
    },
    classes: {
      type: Array,
    },
    clickToClose: {
      default: true,
    },
    isDifficult: {
      default: false,
      type: Boolean,
    },
  },
  setup(props, { slots, attrs }) {
    const modalOpener = () => $vfm.show({
      component: ModalContainer, 
    }, {
      ...props,
      ...attrs,
    })

    return () => slots.default({
      open: () => {
        modalOpener()
      },
    })
  },
}
</script>
