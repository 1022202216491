import {
  useAuth,
} from '@/stores/auth'
import {
  headersMiddleware,
  overrideMiddleware,
} from '@aspectus/resource'
import { storage } from '@/utils/session'
import {
  EDU_DJANGO_LANG,
} from '@/const'
import useAcceptLang from '@/composables/useAcceptLang'


/**
 * Add Authorization header for resource
 * @param resource 
 * @param access 
 * @param refresh 
 * @returns resource with headers
 */
export default async function useAuthToken(resource: Record<string, any>, access?:string|null, refresh?: string): Promise<any> {
  const store = useAuth()
  if (!access) access = store.accessToken || ''

  store.saveToken(access, refresh)

  const HEADERS_MIDDLEWERE = headersMiddleware(overrideMiddleware({
    'Authorization': `Bearer ${access}`
  }))
  return resource.middleware(HEADERS_MIDDLEWERE, 600)
}