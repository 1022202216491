import {
  headersMiddleware,
  overrideMiddleware,
} from '@aspectus/resource'
import { storage } from '@/utils/session'
import {
  EDU_ACCESS_TOKEN_STORAGE_NAME,
  GET_LANGUAGES,
} from '@/const'

export const HEADERS_MIDDLEWERE = headersMiddleware(overrideMiddleware({
  'Authorization': `Bearer ${storage.getItem(EDU_ACCESS_TOKEN_STORAGE_NAME)}`,
  'Accept-Language': storage.getItem(GET_LANGUAGES),
}))
