import { App } from 'vue'

// @ts-ignore
import { createVfm, ModalsContainer, VueFinalModal } from 'vue-final-modal'

export const vfm = createVfm()

import ModalTrigger from './modal-trigger.vue'

export default {
  install(app: App<Element>): void {
    app
      .use(vfm)
      .component('ModalsContainer', ModalsContainer)
      .component('VueFinalModal', VueFinalModal)
      .component('ModalTrigger', ModalTrigger)
  }
}
