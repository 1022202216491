import { DEFAULT_LOCALE, LOCALES } from './const'
import { createI18n, useI18n } from 'vue-i18n'
import { URLsResolver } from './urls'
import { getFormats } from './formats'
import { createHooks } from 'hookable'


export function makeI18n(options: Record<string, any>): any {
  const { locale, messages } = options
  const i18nOptions = {
    ...getFormats(options),
    locale,
    legacy: false,
    globalInjection: true,
    // FIXME: Uncomment check.
    silentTranslationWarn: false, // process.env.NODE_ENV !== 'production',
    messages: messages,
  }
  const i18n = createI18n(i18nOptions);
  
  return i18n;
}

export type I18n = ReturnType<typeof makeI18n>

export type I18nClientConfig = {
  defaultLocale: string
  urlsResolver: URLsResolver
  i18n: I18n
}

export class I18nClient {
  defaultLocale: string
  urlsResolver: URLsResolver
  i18n: I18n
  hooks: ReturnType<typeof createHooks>

  constructor({
    defaultLocale,
    urlsResolver,
    i18n,
  }: I18nClientConfig) {
    this.defaultLocale = defaultLocale
    this.urlsResolver = urlsResolver
    this.i18n = i18n
    this.hooks = createHooks()
  }

  hook(name: string, fn: (locale) => void ): () => void {
    return this.hooks.hook(name, fn)
  }

  getLocale(): string {
    return this.i18n['global'].locale
  }

  async setLocale(locale: string, force = false): Promise<boolean> {

    await this.hooks.callHook('locale:changed', locale, this)
    if (this.getLocale() === locale && !force) {
      return false
    }
    this.i18n['global'].locale = locale

    await this.hooks.callHook('locale:changed', locale, this)

    return true
  }
}

export type ClientMakerOptions = {
  locale?: string
  defaultLocale?: string
  locales?: string[]
  origin?: string
  messages?: any
}

export function makeClient(options: ClientMakerOptions): I18nClient {
  const {
    locale,
    defaultLocale = DEFAULT_LOCALE,
    locales = LOCALES,
    origin,
    messages,
  } = options
  const urlsResolver = new URLsResolver({ defaultLocale, locales, origin })
  const client = new I18nClient({
    defaultLocale,
    urlsResolver,
    i18n: makeI18n({ locale: locale || defaultLocale, messages }),
  })

  return client
}

export type Client = ReturnType<typeof makeClient>
