import { DEFAULT_LOCALE, LOCALES } from './const'

export type URLsResolverConfig = {
  defaultLocale: string
  origin: string | undefined
  locales: string[]
}

export class URLsResolver {
  config: URLsResolverConfig

  constructor({
    defaultLocale = DEFAULT_LOCALE,
    origin,
    locales = LOCALES as unknown as typeof LOCALES[number][],
  }: Partial<URLsResolverConfig> = {}) {
    this.config = {
      defaultLocale, locales, origin
    }
  }

  getLocaleCode(url = ''): string {
    const [lang] = getRelative(url).replace(/^[/]+|[/]+$/g, '').split('/', 1)

    if (!lang || lang.length > 2) {
      return this.config.defaultLocale
    }

    return lang
  }

  removeLocale(path = ''): string {
    const currentLocaleCode = this.getLocaleCode(path)

    if (currentLocaleCode === this.config.defaultLocale) {
      return path
    }

    return path.slice(currentLocaleCode.length + 1)
  }

  prefixLocale(url = '', {
    locale = this.config.defaultLocale,
  } = {}): string {
    const path = this.removeLocale(getRelative(url))

    if (!locale || locale === this.config.defaultLocale) {
      return path
    }

    return getPrefixed(path, `/${locale}/`);
  }

  getAbsolute(url = '', { origin = this.config.origin } = {}): string {
    return getAbsolute(url, { origin })
  }
}

export function clean(url: string): string {
  return url.replace(/\/+/img, '/')
}

export function getRelative(url = ''): string {
  if (!url.startsWith('http') && !url.startsWith('ws')) return url

  const object = new URL(url)

  return object.pathname + object.search + object.hash
}

export function getAbsolute(url = '', { origin }: { origin: any }): string {
  return origin.replace(/\/$/img, '') + getRelative(url)
}

export function getPrefixed(url: string, prefix?: string): string {
  if (!prefix) return url

  return clean(prefix + getRelative(url))
}
