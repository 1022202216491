import { RouteRecordRaw } from 'vue-router'
import createInitialRoutes from '@/router/routes'
import { EDU_CURRENT_ROUTE, EDU_ACCESS_TOKEN_STORAGE_NAME } from '@/const'
import { storage } from '@/utils/session'
import {
  AUTH_PAGE_NAME,
} from '@/components/pages/routes'

const PAGES_PREFIX = '/'

export function createRoutes(options: object): any[] {
  return [
    ...createInitialRoutes(options),
  ];
}

export function scrollBehavior(to: object, savedPosition: boolean): object|boolean {
  if (savedPosition) {
    return savedPosition;
  }

  return {
    x: window.pageXOffset,
    y: window.pageYOffset,
  };
}

type optionsT = { app: any, createInstance: any, createWebHistory: any }

export default function createRouter(options:optionsT): RouteRecordRaw {
  const { createInstance, createWebHistory, app } = options;
  const router = createInstance({
    history: createWebHistory('/'),
    // scrollBehavior,
    routes: createRoutes(options),
  });

  router.beforeEach((to, from, next) => {

    storage.setItem(EDU_CURRENT_ROUTE, to.name)

    if (!storage.getItem(EDU_ACCESS_TOKEN_STORAGE_NAME) && to.name !== AUTH_PAGE_NAME) {
      next({ name: AUTH_PAGE_NAME })
    } else {
      next()
    }
  })

  return router;
}
