import { defineStore } from '@/modules/pinia-hot'
import { storage } from '@/utils/session'
import {
  EDU_ACCESS_TOKEN_STORAGE_NAME,
  EDU_REFRESH_TOKEN_STORAGE_NAME,
} from '@/const'
import executeWithRefresh from '@/composables/useRefresher'
import { getUser } from '@/services/auth.service'

const id = 'auth'

export const useAuth = defineStore(id, {
  state: () => ({
    is_authenticated: !!storage.getItem(EDU_ACCESS_TOKEN_STORAGE_NAME),
    accessToken: storage.getItem(EDU_ACCESS_TOKEN_STORAGE_NAME),
    refreshToken: storage.getItem(EDU_REFRESH_TOKEN_STORAGE_NAME),
    user: {},
  }),

  getters: {
  },

  actions: {
    saveToken(token: string, refresh?: string ) {
      storage.setItem(EDU_ACCESS_TOKEN_STORAGE_NAME, token)
      this.accessToken = token
      if (refresh) {
        storage.setItem(EDU_REFRESH_TOKEN_STORAGE_NAME, refresh)
        this.refreshToken = refresh
      }
    },
    deleteTokens() {
      storage.removeItem(EDU_ACCESS_TOKEN_STORAGE_NAME)
      storage.removeItem(EDU_REFRESH_TOKEN_STORAGE_NAME)
    },
    async getUser(route, token=null) {
      if(storage.getItem(EDU_ACCESS_TOKEN_STORAGE_NAME)) {
        const { item } = (await executeWithRefresh(getUser, token, {}, null, route))
        this.user = item
      }
    },
  },
})

